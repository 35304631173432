<template>
    <div>
        <urlGroupList ></urlGroupList>
    </div>
</template>
<script>

import urlGroupList from '@/components/custom/service_url_group/list.vue';

export default {
    components: {
        urlGroupList
    },
    data () {
        let self = (window.x = this)
        return {
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
