<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="650"
        :mask-closable="true"
    >
        <urlGroupEdit :group_id="edit_group_id" @closePage="closeEditPage"></urlGroupEdit>
    </Drawer>  

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="20" style="text-align: right">
            <Button type="success"  @click="addNewGroup">新增</Button>
        </Col>
    </Row>
    <br />
    <Table 
        max-height="600" 
        stripe 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </Table>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getUrlGroup } from '@/api/custom/service_url_group';
import urlGroupEdit from './edit.vue';

const editButton = (vm, h, params) => {
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_group_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "url-group-list",
    // props: {
    //     sport_id: Number,   // 运动id
    // },
    components: {
        urlGroupEdit
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            edit_group_id : 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    width: 200,
                    key: 'id',
                },
                {
                    title: '名称',
                    align: 'center',
                    width: 200,
                    key: 'name',
                },
                {
                    title: '英文名称',
                    align: 'center',
                    width: 200,
                    key: 'name_en',
                },
                {
                    title: '路由',
                    align: 'center',
                    // width: 200,
                    key: 'urls_json',
                    render(h, params) {
                        // return [ editButton(self, h, params), ]
                        let url_infos = params.row.url_infos;
                        let _result = [];
                        url_infos.forEach(element => {
                            _result.push(
                                h("Row", 
                                    [h('Col', { attrs: { span: 10 }, }, 
                                        [h('span', 
                                            {   
                                                class: "text-main", 
                                            }, 
                                            element.name,
                                        )]
                                    ),
                                    h('Col', { attrs: { span: 14,  }, style:{'text-align': 'left'} }, 
                                        [h('span', 
                                            {   
                                                class: "text-link", 
                                            }, 
                                            element.url,
                                        )]
                                    ),]
                                )
                            )
                        });
                        return _result;
                    }
                },
                {
                    title: 'sport_id',
                    align: 'center',
                    width: 100,
                    key: 'sport_id',
                },
                {
                    title: 'sport_name',
                    width: 150,
                    align: 'center',
                    key: 'sport_name',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let sport = self.$conf.sports[currentRow.sport_id]
                        return h( 'span', sport? sport.name : '未知id: '+ String(currentRow.sport_id) )
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 200,
                    render (h, params) {
                        return [ editButton(self, h, params), ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        addNewGroup(){
            this.edit_group_id=0 ;
            this.drawer_edit=true;
        },

        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        getData () {
            this.loading = true;
            // 搜索日期
            var params = {
                search_key: this.search_key,
                page: this.current_page,
            }
            getUrlGroup(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
            self.getData();
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>