<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Modal
            v-model="modal_pickurl"
            title="编辑路由"
            footer-hide
            :width="80"
        >
            <serviceUrlPicker v-model="data.url_infos" :group_id="0"></serviceUrlPicker>
        </Modal>
        <Form label-position="left" :label-width="80" >
            <FormItem label="id: " v-if="data.id">
                <Row>
                    <Col span="20" >
                        <Input v-model="data.id" disabled></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="名称(描述): " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="英文名称: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_en" ></Input>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="运动: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.sport_id">
                            <Option v-for="item in $conf.sports" :value="item.id" :key="item.id" :label="item.name"></Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="路由: " >
                <Row v-for="(item, index) in data.url_infos" :key="index" >
                    <Col span="8" >
                        <!-- <Input v-model="data.url_infos[index]" ></Input> -->
                        <span class="text-main"> {{item.name}} </span>
                    </Col>
                    <Col span="12" >
                        <span class="text-assist"> {{item.url}} </span>
                    </Col>
                    <Col span="4" >
                        <Icon type="md-close-circle"  @click.native="data.url_infos.splice(index, 1)" />
                    </Col>
                </Row>
                <Row>
                    <Col span="20" >
                        <Button shape="circle" icon="md-add" @click="modal_pickurl=true" ></Button>
                    </Col>
                </Row>
            </FormItem>

            <FormItem label="是否删除: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" >
                            <Option :value="1" key="1">是</Option>
                            <Option :value="0" key="0">否</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getUrlGroup, editUrlGroup } from '@/api/custom/service_url_group';
import serviceUrlPicker from '../basic/service_url_picker.vue';

export default {
    name: "url-group-edit",
    props: {
        group_id: Number,   // 
    },
    components: {
        serviceUrlPicker
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            modal_pickurl: false,
            data: {
                deleted: 0,
            },
        }
    },
    methods: {
        // end 
        getData () {
            let self = this;
            if (self.group_id && self.group_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "id" : this.group_id,
                    "page" : 1, 
                }
                getUrlGroup( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.data = {
                    deleted: 0,
                    url_infos: [],
                }
            }
            
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },
        save() {
            let self = this;
            let urls = [];
            self.data.url_infos.forEach(element => {
                urls.push( element.url);
            });
            let params = {
                id: self.data.id,
                name: self.data.name,
                name_en: self.data.name_en || '',
                urls: urls,
                sport_id: self.data.sport_id,
                deleted: self.data.deleted,
            }
            editUrlGroup(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        'group_id': {
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>